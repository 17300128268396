<template>
  <div class="">
    <div class="">
      <img class="w-full" src="../assets/repas3.jpg" alt="Image 2" />
    </div>
    <div v-show="showAlert">
      <AlertComponent :content="alert.message" type-alert="error" />
    </div>

    <label for="restaurant" class="mb-3 block lg:text-2xl text-xl font-bold mt-9">
      Recherchez un repas
    </label>
    <div
      class="w-3/4 mx-9 rounded-full p-1 mt-2 box-border border border-orange-500 lg:mx-auto lg:my-auto bg-white overflow-hidden ring-red-300 focus:ring-4 w-2/4 flex items-center py-1"
    >
      <input
        type="text"
        v-model="filters"
        class="rounded-full px-4 focus:outline-none lg:w-full"
        placeholder="Recherche ......."
      />
      <button
        class="hidden md:block text-sm bg-green-600 py-2 px-6 rounded-full text-white poppins ring-red-300 focus:ring-4 transition duration-300 hover:scale-105 transform"
      >
        Search
      </button>
    </div>
    <div class="flex mt-6 flex-wrap">
      <div
        v-for="(repas, index) in paginatedCards"
        :key="index"
        class="lg:w-1/4 p-4"
      >
        <div class="bg-white relative">
          <img
            class="h-60 w-72 mx-auto transform transition duration-300 hover:scale-105"
            :src="repas.image_url"
            alt=""
          />
          <button
            class="absolute bottom-48 lg:left-10 left-2 bg-green-500 text-white py-2 px-4 rounded-bl-lg"
          >
            {{ repas.categoris.name }}
          </button>
        </div>
        <div class="flex flex-col items-center my-3 space-y-2">
          <h1 class="text-gray-900 poppins text-lg">{{ repas.name }}</h1>
          <p class="text-gray-500 poppins text-sm text-center">
            {{ repas.description }}
          </p>
          <h2 class="text-gray-900 poppins text-2xl font-bold">
            {{ repas.prix.split(".")[0] }}FCFA
          </h2>
          <div class="flex">
            <button
              @click="RepasModal(repas.id, repas.restaurant.status)"
              class="bg-green-600 text-white mr-6 px-8 py-2 focus:outline-none poppins rounded-full mt-4 transform transition duration-300 hover:scale-105"
            >
              Commander
            </button>

            <button class="ml-4 mt-4" @click="CommentaireModal(repas.id)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-9 h-9"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-center mt-4 mb-4">
      <nav aria-label="Page navigation example">
        <ul class="flex items-center -space-x-px h-8 text-sm">
          <li>
            <a
              @click.prevent="goToPage(currentPage - 1)"
              :class="{ 'opacity-50 cursor-not-allowed': currentPage === 1 }"
              class="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            >
              <span class="sr-only">Previous</span>
              <svg
                class="w-2.5 h-2.5 rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 1 1 5l4 4"
                />
              </svg>
            </a>
          </li>
          <li v-for="page in pages" :key="page">
            <a
              @click.prevent="goToPage(page)"
              :class="{
                'z-10 flex items-center justify-center px-3 h-8 leading-tight': true,
                'text-blue-600 border border-blue-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white':
                  currentPage === page,
                'text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white':
                  currentPage !== page,
              }"
            >
              {{ page }}
            </a>
          </li>

          <li>
            <a
              @click.prevent="goToPage(currentPage + 1)"
              :class="{
                'opacity-50 cursor-not-allowed': currentPage === totalPages,
              }"
              class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            >
              <span class="sr-only">Next</span>
              <svg
                class="w-2.5 h-2.5 rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
  <TheModal
    width="w-full md:w-2/3 lg:w-1/2"
    :is-open="showModalRepas"
    @close-modal="showModalRepas = false"
  >
    <template #header> Faire votre commande</template>

    <template #body>
      <form action="#" method="POST" @submit.prevent="commande()">
        <div>
          <div class="mt-3 sm:mt-0 sm:col-span-2">
            <div class="px-4 py-5 bg-white p-6">
              <div class="grid grid-cols-8 gap-6">
                <div class="col-span-8 sm:col-span-4">
                  <BaseLabel value="Nom " />
                  <BaseInput id="nom" v-model="addform.name" class="mt-2" />
                </div>
                <div class="col-span-8 sm:col-span-4">
                  <BaseLabel value="Addrese" />
                  <BaseInput
                    id="prenom"
                    v-model="addform.adresse"
                    class="mt-2"
                  />
                </div>
                <div class="col-span-8 sm:col-span-8">
                  <BaseLabel value="Télèphone" />
                  <div class="relative mt-1">
                    <BaseInput v-model="addform.contact" class="mt-2" />
                  </div>
                </div>
                <div class="col-span-8 sm:col-span-8">
                  <BaseLabel
                    value="Informations Complémentaires (pas obligatoire)"
                  />
                  <BaseInput
                    id="language"
                    v-model="addform.description"
                    class="mt-2"
                  />
                </div>
                <div class="col-span-8 sm:col-span-8">
                  <BaseLabel value="Qunatité" />
                  <BaseInput
                    id="language"
                    v-model="addform.quantite"
                    class="mt-2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </template>
    <template #footer>
      <AddModalFooter @cancel="showModalRepas = false" @send="commande()" />
    </template>
  </TheModal>
  <TheModal
    width="w-full md:w-2/3 lg:w-1/2"
    :is-open="showModalCommentaires"
    @close-modal="showModalCommentaires = false"
  >
    <template #header> Votre commentaire</template>

    <template #body>
      <form action="#" method="POST" @submit.prevent="commentaire()">
        <div>
          <div class="mt-3 sm:mt-0 sm:col-span-2">
            <div class="px-4 py-5 bg-white p-6">
              <div class="grid grid-cols-8 gap-6">
                <div class="col-span-8 sm:col-span-8">
                  <BaseLabel value="Votre message " />
                  <div class="mt-1">
                    <textarea
                      class="block w-full p-2 border border-input-disable rounded-md focus:outline-none focus:ring-primary-normal focus:ring focus:ring-opacity-50 shadow-sm focus:border"
                      v-model="sendform.content"
                      autocomplete="current-password"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </template>
    <template #footer>
      <AddModalFooter
        @cancel="showModalCommentaires = false"
        @send="commentaire()"
      />
    </template>
  </TheModal>
  <TheModal
    width="w-full md:w-2/3 lg:w-1/4"
    :is-open="showModalCommande"
    @close-modal="showModalCommande = false"
  >
    <template #header> Numero de payement</template>

    <template #body>
      <form action="#" method="POST" @submit.prevent="LivreCommande()">
        <div>
          <div class="mt-3 sm:mt-0 sm:col-span-2">
            <div class="px-4 py-5 bg-white p-6">
              <div class="grid grid-cols-8 gap-6">
                <div
                  v-if="numResto.celtispay !== null"
                  class="col-span-8 sm:col-span-8 flex items-center"
                >
                  <BaseLabel value="Numéro CELTISE" />
                  <label
                    class="block font-medium text-sm text-gray-700 mt-2 bg-gray-200 px-4 rounded-full"
                    >{{ numResto.celtispay }}</label
                  >
                  <svg
                    @click="copyToClipboard(numResto.celtispay)"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 text-green-400 ml-4 mt-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z"
                    />
                  </svg>
                </div>
                <div
                  v-if="numResto.moovpay !== null"
                  class="col-span-8 sm:col-span-8 flex items-center"
                >
                  <BaseLabel value="Numéro MOOV" />
                  <label
                    class="block font-medium text-sm text-gray-700 mt-2 bg-gray-200 px-4 rounded-full"
                    >{{ numResto.moovpay }}</label
                  >
                  <svg
                    @click="copyToClipboard(numResto.moovpay)"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 text-green-400 ml-4 mt-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z"
                    />
                  </svg>
                </div>
                <div
                  v-if="numResto.mtnpay !== null"
                  class="col-span-8 sm:col-span-8 flex items-center"
                >
                  <BaseLabel value="Numéro MTN" />
                  <label
                    class="block font-medium text-sm text-gray-700 mt-2 bg-gray-200 px-4 rounded-full"
                    >{{ numResto.mtnpay }}</label
                  >

                  <svg
                    @click="copyToClipboard(numResto.mtnpay)"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 text-green-400 ml-4 mt-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </template>
  </TheModal>
</template>

<script>
//import { FedaPay, Transaction } from "fedapay";
import {
  openKkiapayWidget,
  addKkiapayListener,
  removeKkiapayListener,
} from "kkiapay";
import TheModal from "../components/TheModal.vue";
import BaseLabel from "../components/BaseLabel.vue";
import BaseInput from "../components/BaseInput.vue";
import AddModalFooter from "../components/AddModalFooter.vue";
import AlertComponent from "../components/AlertComponent.vue";
import axios from "axios";
import Noty from "noty";
import "noty/lib/noty.css";
import "noty/lib/themes/mint.css";
export default {
  name: "App",
  components: {
    TheModal,
    BaseLabel,
    BaseInput,
    AddModalFooter,
    AlertComponent,
  },
  data() {
    return {
      addform: {
        repas_id: "",
        name: "",
        contact: "",
        description: "",
        adresse: "",
        user_id: "",
        quantite: 1,
        status: "En attente",
        montant: 0,
        commande_id: "",
        restaurant_id: "",
      },
      sendform: {
        respas_id: "",
        content: "",
        user_id: "",
      },
      showAlert: false,
      alert: {
        message: "",
      },
      showModalRepas: false,
      showModalCommentaires: false,
      repass: [],
      restaurants: [],
      CommentaireId: "",
      filteredRepas: [],
      commandeID: "",
      transationID: "",
      filters: "",
      currentPage: 1,
      itemsPerPage: 16,
      totalPages: 5,
      userID: "",
      numResto: "",
      statusResto:"",
      showModalCommande: false,
      user: {
        // Assurez-vous d'initialiser l'objet user
        email: "user@example.com", // Remplacez par l'email de l'utilisateur
      },
    };
  },
  mounted() {
    this.profile();
    this.getRepas();
    addKkiapayListener("success", this.successHandler);
  },
  computed: {
    filteredRepa() {
      const searchTerm = this.filters.toLowerCase();
      const filtered_data = this.repass.filter((repass) => {
        const name = repass.name.toLowerCase();
        return name.includes(searchTerm);
      });

      return filtered_data;
    },
    // Calcule les cartes à afficher en fonction de la page actuelle
    // paginatedCards() {
    //   const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    //   const endIndex = startIndex + this.itemsPerPage;
    //   return this.cards.slice(startIndex, endIndex);
    // },
    paginatedCards() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;

      // Vérifier si la page actuelle est valide
      if (startIndex >= this.filteredRepa.length || endIndex <= 0) {
        return []; // Retourner un tableau vide si la page est invalide
      }

      return this.filteredRepa.slice(startIndex, endIndex);
    },
    // Vérifie si le bouton "Charger plus" doit être affiché
    showLoadMoreButton() {
      return this.currentPage * this.itemsPerPage < this.filteredRepa.length;
    },
    // pages() {

    //   return Array.from({ length: this.totalPages }, (_, index) => index + 1);
    // },
    pages() {
      const totalCards = this.filteredRepa.length;
      const totalPages = Math.ceil(totalCards / this.itemsPerPage);
      return Array.from({ length: totalPages }, (_, index) => index + 1);
    },
  },
  beforeUnmount() {
    removeKkiapayListener("success", this.successHandler);
  },
  methods: {
    copyToClipboard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          alert("Numéro copié dans le presse-papiers!");
        })
        .catch((err) => {
          console.error("Erreur lors de la copie: ", err);
        });
    },
    async profile() {
      try {
        const response = await axios.get("/api/profile");
        if (response.data) {
          this.userID = response.data.id;
          console.log(this.userID);
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    CommentaireModal(id) {
      this.showModalCommentaires = !this.showModalCommentaires;
      this.sendform.respas_id = id;
    },
   RepasModal(id, repasStatus) {
  this.statusResto = repasStatus;
  
  if (this.statusResto !== 'disponible') {
    this.showModalRepas = false;
        alert('Le restaurant est fermé');

  } else {
    this.showModalRepas = !this.showModalRepas;
    this.addforms.repas_id = id;
    this.filteredMenus = this.menus.filter(
      (repas) => repas.id === this.addforms.repas_id
    );
  }
}
,

    async commande() {
      try {
        this.filters = "";
        this.addform.montant =
          this.filteredRepas[0].prix * this.addform.quantite;
        this.addform.restaurant_id = this.filteredRepas[0].restaurant.id;
        this.addform.user_id = this.userID;
        const data = {
          name: this.addform.name,
          description: this.addform.description,
          contact: this.addform.contact,
          adresse: this.addform.adresse,
          montant: this.addform.montant,
          status: this.addform.status,
          restaurant_id: this.addform.restaurant_id,
          user_id: this.addform.user_id,
        };

        const response = await axios.post("/api/commandes", data);
        if (response.status == 201) {
          this.commandeID = response.data.data.id;
          this.showModalRepas = !this.showModalRepas;
          (this.numResto = response.data.data.restaurant),
            console.log(this.numResto);
          this.lignecommande();
        }
      } catch (error) {
        if (error.response.status !== 500) {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      }
    },
    async lignecommande() {
      try {
        this.filters = "";
        this.addform.montant =
          this.filteredRepas[0].prix * this.addform.quantite;
        const datas = {
          quantite: this.addform.quantite,
          repas_id: this.addform.repas_id,
          commande_id: this.commandeID,
          montant: this.addform.montant,
        };

        const response = await axios.post("/api/lignecommandes", datas);
        if (response.status == 201) {
          this.showModalCommande = true;
          //this.createTransaction();
        }
      } catch (error) {
        if (error.response.status !== 500) {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      }
    },
    async payementmethod() {
      try {
        const data = {
          transationId: this.transationID,
          commande_id: this.commandeID,
        };

        const response = await axios.post("/api/payments", data);
        if (response.status == 201) {
          this.addform = {};
          new Noty({
            type: "success",
            layout: "topRight",
            text: "Votre commande a été enregistrée avec succès.",
            timeout: 5000,
          }).show();
        } else {
          this.showModalRepas = !this.showModalRepas;
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      } catch (error) {
        if (error.response.status !== 500) {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      }
    },
    async commentaire() {
      try {
        this.sendform.user_id = this.userID;
        const response = await axios.post(
          "/api/commentairerespas",
          this.sendform
        );
        if (response.status == 201) {
          this.showModalCommentaires = !this.showModalCommentaires;
          this.sendform = {};
          new Noty({
            type: "success",
            layout: "topRight",
            text: "Merci pour votre commentaire",
            timeout: 5000,
          }).show();
        } else {
          this.showModalCommentaires = !this.showModalCommentaires;
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      } catch (error) {
        if (error.response.status !== 500) {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      }
    },
    async getRepas() {
      try {
        const response = await axios.get("/api/repas");
        if (response.data) {
          this.repass = response.data.data;
          console.log(this.repass);
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    async createTransaction() {
      this.addform.montant = this.filteredRepas[0].prix * this.addform.quantite;
      openKkiapayWidget({
        amount: this.addform.montant,
        firstname: this.addform.name,
        lastname: this.addform.name,
        api_key: "361197d0e64411ec848227abfc492dc7",
        sandbox: true,
        phone: "61000000",
      });
    },
    successHandler(response) {
      console.log(response);
      this.transationID = response.transactionId;
      this.payementmethod();
    },
    nextSlide() {
      if (this.currentIndex + 3 < this.slides.length) {
        this.currentIndex += 1;
        this.updateSlider();
      }
    },
    prevSlide() {
      if (this.currentIndex > 0) {
        this.currentIndex -= 1;
        this.updateSlider();
      }
    },
    updateSlider() {
      const translateValue = -this.currentIndex * (100 / 3) + "%";
      this.$refs.slider.style.transform = "translateX(" + translateValue + ")";
    },
    goToPage(page) {
      // if (page >= 1 && page <= this.pages) {
      //   this.currentPage = page;
      // }
      this.currentPage = page;
    },
    loadMore() {
      // Ajoutez ici la logique pour charger plus d'éléments depuis votre source de données (définie localement)
      // Assurez-vous d'ajuster cette logique en fonction de votre situation réelle
      try {
        const newCards = this.getMoreCards(); // Remplacez par votre logique pour récupérer plus de cartes localement
        this.cards = this.cards.concat(newCards);
        this.currentPage++;
      } catch (error) {
        console.error(
          "Erreur lors du chargement des données supplémentaires",
          error
        );
      }
    },
  },
};
</script>

<style scoped>
.custom-background {
  /* Remplacez 'votre-image.jpg' par le nom de votre image */
  background-size: cover;
  /* Ajustez la taille de l'image */
  background-repeat: no-repeat;
  background-position: center center;
  /* Centrez l'image */
  /* Ajoutez d'autres styles Tailwind CSS ou CSS personnalisés au besoin */
}

/* Ajoutez des styles personnalisés pour le slider ici */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.cursor-pointer {
  cursor: pointer;
}
.ml-2 {
  margin-left: 0.5rem;
}
</style>
