<template>
  <div class="">
    <div v-show="showAlert">
      <AlertComponent :content="alert.message" type-alert="error" />
    </div>
    <div class="custom-background flex">
      <div
        class="w-1/2 flex flex-col items-center justify-start font-sans min-h-96 lg:pt-10 lg:pb-20"
      >
        <div class="mt-16 lg:mt-32">
          <!-- <div class="lg:ml-9 flex">
            <img src="../assets/logo.png" alt="Logo" class="h-24 w-auto" />
            <div class="flex bg-green-500 h-12 px-2 py-2 rounded-lg shadow-2xl mt-6 ml-16">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 text-white"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                />
              </svg>

              <router-link
                to="/commande"
                class="font-semibold text-center text-white ml-2"
              >
                Dashboard
              </router-link>
            </div>
          </div> -->
          <p
            class="p-2 text-2xl font-bold text-center lg:text-5xl text-gray-100"
          >
            Pourquoi rester affamé quand vous pouvez commander
          </p>
          <p
            class="p-2 text-2xl font-bold text-center lg:text-5xl text-gray-100"
          >
            chez WADOUNNOU ?
          </p>
        </div>
        <div>
          <p
            class="p-4 pt-6 font-sans text-2xl leading-10 text-center text-gray-200"
          >
            Téléchargez dès maintenant l'application WADOUNNOU
          </p>
        </div>
        <div
          class="relative z-50 flex flex-col items-center justify-between h-48 lg:space-x-8 pt-7 lg:pt-0 lg:flex-row lg:justify-between lg:w-90"
        >
          <a
            href="https://bucetwadounou.s3.amazonaws.com/medias/app-release.apk"
            class="pt-3 pb-3 pl-12 pr-12 text-2xl font-semibold text-center text-white transition-all bg-orange-600 rounded-full shadow-2xl lg:ml-5 hover:bg-orange-700 focus:outline-none ring-4 ring-orange-600 lg:ring-2 lg:font-medium"
          >
            Téléchargez l’apk
          </a>
          <a
            href="#"
            class="pt-3 pb-3 text-2xl hidden md:block font-semibold text-center text-orange-500 transition-all rounded-full shadow-2xl lg:mr-5 hover:text-orange-500 hover:bg-gray-50 pl-11 pr-11 bg-gray-50 focus:outline-none ring-4 ring-orange-500 lg:font-medium lg:text-gray-50 lg:bg-opacity-0 lg:ring-2 lg:ring-white"
          >
            App Store
          </a>
        </div>
      </div>
      <div class="w-1/2">
        <div class="hidden md:block items-end">
          <img class="lg:mt-16" src="../assets/img1.png" alt="" />
        </div>
      </div>
    </div>

    <div class="flex">
      <div class="lg:w-1/3">
        <div class="hidden md:block items-start">
          <img class="" src="../assets/img2.png" alt="" />
        </div>
      </div>
      <div class="w-2/3 font-sans min-h-96 mt-24 mx-9">
        <div class="flex flex-wrap -mx-2">
          <div
            v-for="meal in visibleMeals"
            :key="meal.id"
            class="lg:w-1/3 mt-4 px-2"
          >
            <transition name="fade" mode="out-in">
              <div>
                <img
                  :src="meal.image_url"
                  :alt="meal.name"
                  class="h-64 w-64 object-cover rounded-full"
                />
                <p class="text-center text-xl font-sans font-bold mt-2">
                  {{ meal.name }}
                </p>
              </div>
            </transition>
          </div>
        </div>
        <div class="flex justify-center space-x-4 mt-9">
          <router-link
            to="/commande"
            class="py-2 w-52 px-4 text-2xl font-semibold text-center text-white transition-all rounded-full shadow-2xl bg-green-500"
          >
            Commander
          </router-link>
          <router-link
            to="/repas"
            class="py-2 w-52 px-4 text-2xl font-semibold text-center text-white transition-all rounded-full shadow-2xl"
            style="background-color: #ff9900"
          >
            Nos repas
          </router-link>
          <router-link
            to="/reservation"
            class="py-2 w-52 px-4 text-2xl font-semibold text-center text-white transition-all rounded-full shadow-2xl bg-purple-500"
          >
            Réservation
          </router-link>
        </div>
      </div>
    </div>
    <h1 class="text-4xl font-bold border border-green-400 mt-4"></h1>
    <div class="flex">
      <div class="w-2/3 font-sans min-h-96 mt-24 mx-9">
        <div class="flex flex-wrap -mx-2">
          <div
            v-for="meal in restaurants"
            :key="meal.id"
            class="lg:w-1/3 mt-4 px-2"
          >
            <transition name="fade" mode="out-in">
              <div>
                <img
                  :src="meal.image_url"
                  :alt="meal.name"
                  class="h-52 w-80 object-cover rounded-lg"
                />
                <p class="text-center text-xl font-sans font-bold mt-2">
                  {{ meal.name }}
                </p>
              </div>
            </transition>
          </div>
        </div>
        <div class="flex justify-center space-x-4 mt-9">
          <router-link
            to="/restaurant"
            class="py-2 w-52 px-4 text-2xl font-semibold text-center text-white transition-all rounded-full shadow-2xl"
            style="background-color: #ff9900"
          >
            Nos restaurants
          </router-link>
          <router-link
            to="/propos"
            class="py-2 w-52 px-4 text-2xl font-semibold text-center text-white transition-all rounded-full shadow-2xl bg-purple-500"
          >
            A propos
          </router-link>
        </div>
      </div>
      <div class="lg:w-1/3">
        <div class="hidden md:block items-start">
          <img class="" src="../assets/phone4.jpg" alt="" />
        </div>
      </div>
    </div>
    <section class="pt-4 pb-12 bg-gray-800 mt-6">
      <div class="my-16 text-center">
        <h2
          class="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-blue-500"
        >
          Quelques temoignages
        </h2>
        <p class="text-xl text-white">
          L'entreprise elle-même est une entreprise très prospère. lâche sage
        </p>
      </div>
      <!--<div class="grid gap-2 lg:grid-cols-3 mx-4">
        <div class="max-w-md p-4 bg-white rounded-lg shadow-lg">
          <div class="flex justify-center -mt-16 md:justify-end">
            <img
              class="object-cover w-20 h-20 border-2 border-green-500 rounded-full"
              src="https://images.unsplash.com/photo-1499714608240-22fc6ad53fb2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80"
            />
          </div>
          <div>
            <h2 class="text-3xl font-semibold text-gray-800">
              Groupe Tournier
            </h2>
            <p class="mt-2 text-gray-600">
              Nous apprécions tout particulièrement la facilité d'utilisation de
              l'application WADOUNOU tant au niveau du paramétrage que dans leur
              utilisation quotidienne par nos équipes permettant une prise en
              main rapide et efficace.
            </p>
          </div>
          <div class="flex justify-end mt-4">
            <a href="#" class="text-xl font-medium text-green-500">Yassine</a>
          </div>
        </div>
        <div class="max-w-md p-4 bg-white rounded-lg shadow-lg mt-12 lg:mt-0">
          <div class="flex justify-center -mt-16 md:justify-end">
            <img
              class="object-cover w-20 h-20 border-2 border-green-500 rounded-full"
              src="https://cdn.pixabay.com/photo/2018/01/04/21/15/young-3061652__340.jpg"
            />
          </div>
          <div>
            <h2 class="text-3xl font-semibold text-gray-800">Services</h2>
            <p class="mt-2 text-gray-600">
              L'interface utilisateur est incroyablement conviviale, ce qui
              facilite la navigation et la recherche de mes plats préférés. Le
              choix de restaurants est vaste, offrant une variété de cuisines
              pour satisfaire tous les goûts. Je suis constamment impressionné
              par la rapidité avec laquelle je peux passer ma commande
            </p>
          </div>
          <div class="flex justify-end mt-4">
            <a href="#" class="text-xl font-medium text-green-500"
              >Uriel FONTON</a
            >
          </div>
        </div>
        <div class="max-w-md p-4 bg-white rounded-lg shadow-lg mt-12 lg:mt-0">
          <div class="flex justify-center -mt-16 md:justify-end">
            <img
              class="object-cover w-20 h-20 border-2 border-green-500 rounded-full"
              src="https://cdn.pixabay.com/photo/2018/01/18/17/48/purchase-3090818__340.jpg"
            />
          </div>
          <div>
            <h2 class="text-3xl font-semibold text-gray-800">Application</h2>
            <p class="mt-2 text-gray-600">
              J'utilise WADOUNOU depuis quelques mois maintenant, et cela a
              vraiment transformé la façon dont je commande de la nourriture.
              C'est de loin la meilleure application de livraison que j'ai
              essayée.
            </p>
          </div>
          <div class="flex justify-end mt-4">
            <a href="#" class="text-xl font-medium text-green-500"
              >John GAGNON</a
            >
          </div>
        </div>
      </div>-->
    </section>
  </div>
  <!-- Composant de modal -->
  <ModalComponent :isOpen="isModalOpen" @close="closeModal" />
  <TheModal
    width="w-3/4 md:w-2/3 lg:w-1/2"
    :is-open="showModalRepas"
    @close-modal="showModalRepas = false"
  >
    <template #header> Faire votre commande</template>

    <template #body>
      <form action="#" method="POST" @submit.prevent="commande()">
        <div>
          <div class="mt-3 sm:mt-0 sm:col-span-2">
            <div class="px-4 py-5 bg-white p-6">
              <div class="grid grid-cols-8 gap-6">
                <div class="col-span-8 sm:col-span-4">
                  <BaseLabel value="Nom " />
                  <BaseInput id="nom" v-model="addform.name" class="mt-2" />
                </div>
                <div class="col-span-8 sm:col-span-4">
                  <BaseLabel value="Addrese" />
                  <BaseInput
                    id="prenom"
                    v-model="addform.adresse"
                    class="mt-2"
                  />
                </div>
                <div class="col-span-8 sm:col-span-8">
                  <BaseLabel value="Télèphone" />
                  <div class="relative mt-1">
                    <BaseInput v-model="addform.contact" class="mt-2" />
                  </div>
                </div>
                <div class="col-span-8 sm:col-span-8">
                  <BaseLabel
                    value="Informations Complémentaires (pas obligatoire)"
                  />
                  <BaseInput
                    id="language"
                    v-model="addform.description"
                    class="mt-2"
                  />
                </div>
                <div class="col-span-8 sm:col-span-8">
                  <BaseLabel value="Qunatité" />
                  <BaseInput
                    id="language"
                    v-model="addform.quantite"
                    class="mt-2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </template>
    <template #footer>
      <AddModalFooter @cancel="showModalRepas = false" @send="commande()" />
    </template>
  </TheModal>
  <TheModal
    width="w-2/3 md:w-2/3 lg:w-1/2"
    :is-open="showModalCommentaires"
    @close-modal="showModalCommentaires = false"
  >
    <template #header> Votre commentaire</template>

    <template #body>
      <form action="#" method="POST" @submit.prevent="commentaire()">
        <div>
          <div class="mt-3 sm:mt-0 sm:col-span-2">
            <div class="lg:px-4 py-5 bg-white lg:p-6 px-2">
              <div class="grid grid-cols-8 gap-6">
                <div class="col-span-8 sm:col-span-8">
                  <BaseLabel value="Nom " />
                  <BaseInput id="nom" class="mt-2" />
                </div>
                <div class="col-span-8 sm:col-span-8">
                  <BaseLabel value="Votre message " />
                  <div class="mt-1">
                    <textarea
                      class="block lg:w-full p-2 border border-input-disable rounded-md focus:outline-none focus:ring-primary-normal focus:ring focus:ring-opacity-50 shadow-sm focus:border"
                      v-model="sendform.content"
                      autocomplete="current-password"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </template>
    <template #footer>
      <AddModalFooter
        @cancel="showModalCommentaires = false"
        @send="commentaire()"
      />
    </template>
  </TheModal>
</template>

<script>
import axios from "axios";
import Noty from "noty";
import "noty/lib/noty.css";
import "noty/lib/themes/mint.css";
import {
  openKkiapayWidget,
  addKkiapayListener,
  removeKkiapayListener,
} from "kkiapay";
import ModalComponent from "../components/ModalComponent .vue";
import TheModal from "../components/TheModal.vue";
import BaseLabel from "../components/BaseLabel.vue";
import BaseInput from "../components/BaseInput.vue";
import AddModalFooter from "../components/AddModalFooter.vue";
import AlertComponent from "../components/AlertComponent.vue";
//import spiner from "../components/spiner.vue";

export default {
  name: "App",
  components: {
    ModalComponent,
    TheModal,
    BaseLabel,
    BaseInput,
    AlertComponent,
    AddModalFooter,
    //spiner
  },
  data() {
    return {
      images: [
        { src: require("@/assets/breakfast2.png"), name: "Petit-déjeuner 1" },
        {
          src: require("@/assets/breakfast4.png"),
          name: "Petit-déjeuner Bénin",
        },
        { src: require("@/assets/breakfast6.png"), name: "Petit-déjeuner " },
        { src: require("@/assets/lunch3.png"), name: "Déjeuner" },
      ],

      image: [
        { src: require("@/assets/lunch4.png"), name: "Plat Africa" },
        { src: require("@/assets/lunch5.png"), name: "Plat Bénin" },
        { src: require("@/assets/lunch6.png"), name: "Déjeuner Sud" },
        { src: require("@/assets/dinner2.png"), name: "Déjeuner Nord" },
      ],

      //currentImage: 0,
      currentIndex: 0,
      currentImageIndex: 0,
      showAlert: false,
      alert: {
        message: "",
      },
      isModalOpen: false,
      addform: {
        repas_id: "",
        name: "",
        contact: "",
        description: "",
        adresse: "",
        user_id: "",
        quantite: 1,
        status: "En attente",
        montant: 0,
        commande_id: "",
      },
      sendform: {
        repas_id: "",
        content: "",
      },
      showModalRepas: false,
      showModalCommentaires: false,
      isLoading: false,
      repass: [],
      restaurants: [],
      CommentaireId: "",
      filteredRepas: [],
      pris: 0,
      filters: "",
      filter: "",
      commandeID: "",
      transationID: "",
      currentPage: 1,
      itemsPerPage: 16,
      totalPages: 5,
    };
  },
  computed: {
    visibleMeals() {
      return this.repass.slice(this.currentIndex, this.currentIndex + 3);
    },
  },
  mounted() {
    //this.startSlider();
    this.getRepas();
    this.restaurant();
    this.startCarousel();
    addKkiapayListener("success", this.successHandler);
  },
  beforeUnmount() {
    removeKkiapayListener("success", this.successHandler);
    clearInterval(this.carouselInterval);
  },
  methods: {
    async restaurant() {
      try {
        const response = await axios.get("/api/restaurants");
        if (response.data) {
          this.restaurants = response.data.data;
          this.filteredRestaurants = this.restaurants.filter(
            (restaurant) => restaurant.user.id === this.user
          );
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    startCarousel() {
      this.carouselInterval = setInterval(() => {
        // Incrémente l'index pour défiler
        this.currentIndex = (this.currentIndex + 1) % this.repass.length;

        // Boucle pour rester dans les limites
        if (this.currentIndex + 3 > this.repass.length) {
          this.currentIndex = 0;
        }
      }, 3000); // Intervalle de 3 secondes
    },

    // startSlider() {
    //   setInterval(() => {
    //     this.currentImage = (this.currentImage + 1) % this.images.length;
    //   }, 5000);
    // },

    async payementmethod() {
      try {
        const data = {
          transationId: this.transationID,
          commande_id: this.commandeID,
        };

        const response = await axios.post("/api/payments", data);
        if (response.status == 201) {
          this.addform = {};
          new Noty({
            type: "success",
            layout: "topRight",
            text: "Votre commande a été enregistrée avec succès.",
            timeout: 5000,
          }).show();
        } else {
          this.showModalRepas = !this.showModalRepas;
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      } catch (error) {
        if (error.response.status !== 500) {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      }
    },

    async getRepas() {
      try {
        const response = await axios.get("/api/repas");
        if (response.data) {
          this.repass = response.data.data;
        }
      } catch (error) {
        console.log(error.data);
      }
    },

    async createTransaction() {
      this.addform.montant = this.filteredRepas[0].prix * this.addform.quantite;
      openKkiapayWidget({
        amount: this.addform.montant,
        firstname: this.addform.name,
        lastname: this.addform.name,
        api_key: "361197d0e64411ec848227abfc492dc7",
        sandbox: true,
        phone: "61000000",
      });
    },
    successHandler(response) {
      this.transationID = response.transactionId;

      this.payementmethod();
    },

    statusClass(status) {
      return {
        "bg-green-500": status === "disponible",
        "bg-red-500": status === "occuper",
      };
    },
  },
};
</script>

<style scoped>
.custom-background {
  background-image: url("~@/assets/hero.png");
  /* Remplacez 'votre-image.jpg' par le nom de votre image */
  background-size: cover;
  /* Ajustez la taille de l'image */
  background-repeat: no-repeat;
  background-position: center center;
  /* Centrez l'image */
  /* Ajoutez d'autres styles Tailwind CSS ou CSS personnalisés au besoin */
}
.bg-green-500 {
  background-color: #38a169; /* Vert */
}

.bg-red-500 {
  background-color: #e53e3e; /* Rouge */
}

/* Ajoutez des styles personnalisés pour le slider ici */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@keyframes slide {
  0%,
  100% {
    transform: translateX(0%);
  }

  25% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(-200%);
  }

  75% {
    transform: translateX(-300%);
  }
}
</style>
