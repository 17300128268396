<template>
  <section class="py-20 bg-gray-50 ">
    <div class="container mx-6">
      <div class="lg:flex">
        <div class="lg:flex lg:w-2/3">
          <div class="order-1 w-full px-3 lg:w-1/2 lg:order-0">
            <div class="w-full lg:max-w-md">
              <h2 class="mb-4 text-2xl font-bold">À propos de nous</h2>
              <h2
                class="mb-4 text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-blue-500"
              >
                POURQUOI NOUS CHOISIR?
              </h2>

              <p class="mb-4 font-medium text-gray-400 text-left">
                L'objectif principal de l'application web et mobile Wadonnou est
                de simplifier l'achat et la livraison des repas à un coût
                abordable et de manière efficace. Ses fonctionnalités clés
                comprennent la possibilité pour les livreurs de créer des
                profils détaillés, comprenant leur nom, prénom, photo de profil,
                coordonnées et pièces d’identité. Chaque livreur dispose
                également d'une interface dédiée où il peut consulter ses
                commandes en cours pour la livraison.
              </p>
              <p class="mb-4 font-medium text-gray-400 text-left">
                Les propriétaires de restaurants ont la possibilité de créer un
                compte et de renseigner les informations relatives à leur
                établissement. Ils peuvent ajouter leurs menus, suivre les
                commandes en cours, affecter les commandes à un livreur, et bien
                d'autres fonctionnalités encore.
              </p>
              <p class="mb-4 font-medium text-gray-400 text-left">
                Quant aux clients, ils peuvent passer leurs commandes et
                effectuer des réservations en toute simplicité. Pour faciliter
                les transactions, un système de paiement sécurisé est intégré,
                offrant des options de facturation flexibles.
              </p>
              <ul>
                <li class="flex items-center py-2 space-x-4 xl:py-3">
                  <svg
                    class="w-8 h-8 text-gray-500"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z"
                    ></path>
                  </svg>
                  <span class="font-medium text-gray-500"
                    >Traitement et livraison plus rapides</span
                  >
                </li>
                <li class="flex items-center py-2 space-x-4 xl:py-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-8 h-8 text-gray-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <span class="font-medium text-gray-500"
                    >Paiements faciles</span
                  >
                </li>
                <li class="flex items-center py-2 space-x-4 xl:py-3">
                  <svg
                    class="w-8 h-8 text-gray-500"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                    ></path>
                  </svg>
                  <span class="font-medium text-gray-500"
                    >Protection et sécurité à 100% pour votre application
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div class="w-full px-3 mb-12 lg:w-1/2 order-0 lg:order-1 lg:mb-0">
            <img
              class="mx-auto"
              src="../assets/phone4.png"
              alt="feature image"
            />
          </div>
        </div>
        <div class="lg:w-1/3  p-9 ">
        <form
          action="#"
          method="POST"
          @submit.prevent="temoignage()"
      
        >
          <div class="w-full">
            <h1
              class="mb-4 text-2xl font-bold text-center text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-blue-500"
            >
              Votre avis de nos services
            </h1>
            <div class="gap-2 mb-8 lg:flex">
              <div class="w-full">
                <input
                  type="text"
                  v-model="addform.name"
                  class="w-full px-4 py-2 text-base border rounded-md focus:border-green-400 focus:outline-none focus:ring-1 focus:ring-green-600"
                  placeholder="Votre nom"
                />
              </div>
            </div>

            <div class="">
              <textarea
                name=""
                id=""
                v-model="addform.content"
                rows="8"
                cols="30"
                class="w-full p-3 text-base border rounded-md focus:border-green-400 focus:outline-none focus:ring-1 focus:ring-green-600"
                placeholder=" Votre message"
              ></textarea>
            </div>
            <button
              class="px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-green-600 border border-transparent rounded-lg active:bg-green-600 hover:bg-green-700 focus:outline-none focus:shadow-outline-green"
              href="#"
            >
              
              <span v-if="isLoading">
                <spiner />
              </span>
              <span v-else>Envoyer</span>
            </button>
          </div>
        </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Noty from "noty";
import "noty/lib/noty.css";
import "noty/lib/themes/mint.css";
import spiner from "../components/spiner.vue";
export default {
  name: "App",
  components: {
    spiner,
  },
  data() {
    return {
      addform: {
        name: "",
        content: "",
        status: "En attente",
        
      },
      isLoading: false,
    };
  },

  methods: {
  

    async temoignage() {
      try {
        this.isLoading = true;
        const response = await axios.post("/api/temoinanges", this.addform);
        if (response.status == 201) {
          this.addform = {};
          this.isLoading = false;
          new Noty({
            type: "success",
            layout: "topRight",
            text: "Votre témoignage a été envoyé avec succès.",
            timeout: 5000,
          }).show();
          window.scrollTo(0, 0);
          this.$router.push("/");
        }
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.custom-background {
  /*background-image: url("~@/assets/IMG_CommandeLigne(1)_large.png");*/
  /* Remplacez 'votre-image.jpg' par le nom de votre image */
  background-size: cover;
  /* Ajustez la taille de l'image */
  background-repeat: no-repeat;
  background-position: center center;
  /* Centrez l'image */
  /* Ajoutez d'autres styles Tailwind CSS ou CSS personnalisés au besoin */
}

/* Ajoutez des styles personnalisés pour le slider ici */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
