<template>
  <div class="">
    <div class="">
      <img class="w-full" src="../assets/restaurant.jpg" alt="Image 2" />
    </div>
    <div class="shadow-sm bg-gray-100 lg:hidden">
      <label
        for="restaurant"
        class="mb-2 block lg:text-xl text-lg font-bold mt-4"
      >
        Recherchez la ville
      </label>
      <div class="flex mx-4">
        <input
          type="text"
          v-model="filter"
          placeholder=""
          class="w-full rounded-l-lg border border-gray-400 bg-white py-3 px-6"
        />
        <button
          type="submit"
          class="bg-green-600 text-white rounded-r px-2 md:px-3 py-0 md:py-1"
        >
          Search
        </button>
      </div>
      <label
        for="restaurant"
        class="mb-2 block lg:text-xl text-lg font-bold mt-4"
      >
        Recherchez le quartier
      </label>
      <div class="flex mx-4">
        <input
          type="text"
          v-model="filters"
          placeholder=""
          class="w-full rounded-l-lg border border-gray-400 bg-white py-3 px-6"
        />
        <button
          type="submit"
          class="bg-green-600 text-white rounded-r px-2 md:px-3 py-0 md:py-1"
        >
          Search
        </button>
      </div>
      <label
        for="restaurant"
        class="mb-2 block lg:text-xl text-lg font-bold mt-4"
      >
        Recherchez le nom
      </label>
      <div class="flex mx-4">
        <input
          type="text"
          v-model="filterse"
          placeholder=""
          class="w-full rounded-l-lg border border-gray-400 bg-white py-3 px-6"
        />
        <button
          type="submit"
          class="bg-green-600 text-white rounded-r px-2 md:px-3 py-0 md:py-1"
        >
          Search
        </button>
      </div>
      <!--<label
          for="restaurant"
          class="mb-2 block lg:text-xl text-lg font-bold mt-4"
        >
          Recherchez la spécialité
        </label>
        <div class="flex mx-4">
          <input
            type="text"
            v-model="filte"
            placeholder=""
            class="w-full rounded-l-lg border border-gray-400 bg-white py-3 px-6"
          />
          <button
            type="submit"
            class="bg-green-600 text-white rounded-r px-2 md:px-3 py-0 md:py-1"
          >
            Search
          </button>
        </div>-->
    </div>
    <div class="lg:flex">
      <div class="flex mt-4 flex-wrap lg:w-3/4">
        <div
          v-for="(repas, index) in filteredRestau"
          :key="index"
          class="lg:w-1/3 p-4"
        >
          <router-link :to="`/restaurantdetail/${repas.id}`">
            <div class="bg-white relative">
              <img
                class="h-52 w-96 rounded-lg mx-auto transform transition duration-300 hover:scale-105"
                :src="repas.image_url"
                alt=""
              />
              <!-- Bouton vert "Disponible" -->
              <button
          class="absolute bottom-2 left-0 text-white py-1 px-2 rounded-bl-lg"
          :class="statusClass(repas.status)"
        >
          {{ repas.status }}
        </button>
              <button
                class="absolute bottom-36 lg:left-72 left-64 text-white bg-yellow-300 py-1 px-4 rounded-bl-lg"
              >
                {{ repas.moy_note || 0 }}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 text-green-600"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
                  />
                </svg>
              </button>
            </div>
            <!-- Contenu supplémentaire -->
            <div class="flex flex-col my-3 space-y-2">
              <h1 class="text-gray-900 poppins text-lg">{{ repas.name }}</h1>
              <p class="text-gray-500 poppins text-sm flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                  />
                </svg>
                <span class="mt-0 ml-4"
                  >{{ repas.ville }} {{ repas.quatier }}
                  {{ repas.adresse }}</span
                >
              </p>
            </div>
          </router-link>
        </div>
      </div>
      <div class="w-1/4 shadow-sm bg-gray-100 hidden md:block">
        <label
          for="restaurant"
          class="mb-2 block lg:text-xl text-lg font-bold mt-4"
        >
          Recherchez la ville
        </label>
        <div class="flex mx-4">
          <input
            type="text"
            v-model="filter"
            placeholder=""
            class="w-full rounded-l-lg border border-gray-400 bg-white py-3 px-6"
          />
          <button
            type="submit"
            class="bg-green-600 text-white rounded-r px-2 md:px-3 py-0 md:py-1"
          >
            Search
          </button>
        </div>
        <label
          for="restaurant"
          class="mb-2 block lg:text-xl text-lg font-bold mt-4"
        >
          Recherchez le quartier
        </label>
        <div class="flex mx-4">
          <input
            type="text"
            v-model="filters"
            placeholder=""
            class="w-full rounded-l-lg border border-gray-400 bg-white py-3 px-6"
          />
          <button
            type="submit"
            class="bg-green-600 text-white rounded-r px-2 md:px-3 py-0 md:py-1"
          >
            Search
          </button>
        </div>
        <label
          for="restaurant"
          class="mb-2 block lg:text-xl text-lg font-bold mt-4"
        >
          Recherchez le nom
        </label>
        <div class="flex mx-4">
          <input
            type="text"
            v-model="filterse"
            placeholder=""
            class="w-full rounded-l-lg border border-gray-400 bg-white py-3 px-6"
          />
          <button
            type="submit"
            class="bg-green-600 text-white rounded-r px-2 md:px-3 py-0 md:py-1"
          >
            Search
          </button>
        </div>
        <!--<label
          for="restaurant"
          class="mb-2 block lg:text-xl text-lg font-bold mt-4"
        >
          Recherchez la spécialité
        </label>
        <div class="flex mx-4">
          <input
            type="text"
            v-model="filte"
            placeholder=""
            class="w-full rounded-l-lg border border-gray-400 bg-white py-3 px-6"
          />
          <button
            type="submit"
            class="bg-green-600 text-white rounded-r px-2 md:px-3 py-0 md:py-1"
          >
            Search
          </button>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "App",
  data() {
    return {
      restaurants: [],
      filter: "",
      filters: "",
      filte: "",
      filterse: "",
    };
  },
  mounted() {
    this.restaurant();
  },
  computed: {
    filteredRestaurant() {
      const searchTerm = this.filter.toLowerCase();
      const filtered_data = this.restaurants.filter((restaurants) => {
        const name = restaurants.user.id.toLowerCase();
        return name.includes(searchTerm);
      });

      return filtered_data;
    },
    filteredRestaurantvilles() {
      const searchTerm = this.filter.toLowerCase();
      const filtered_data = this.restaurants.filter((restaurants) => {
        const ville = restaurants.ville.toLowerCase();
        return ville.includes(searchTerm);
      });
      return filtered_data;
    },
    filteredRestaurantaddress() {
      const searchTerm = this.filters.toLowerCase();
      const filtered_data = this.filteredRestaurantvilles.filter(
        (restaurants) => {
          const adresse = restaurants.adresse.toLowerCase();
          return adresse.includes(searchTerm);
        }
      );
      return filtered_data;
    },
    filteredRestau() {
      const searchTerm = this.filterse.toLowerCase();
      const filtered_data = this.filteredRestaurantaddress.filter(
        (restaurants) => {
          const name = restaurants.name.toLowerCase();
          return name.includes(searchTerm);
        }
      );

      return filtered_data;
    },
    filteredRestauSpeciliste() {
      const searchTerm = this.filte.toLowerCase();
      const filtered_data = this.filteredRestau.filter((restaurants) => {
        const specilite = restaurants.specilite.toLowerCase();
        return specilite.includes(searchTerm);
      });

      return filtered_data;
    },

    // Calcule les cartes à afficher en fonction de la page actuelle
    // paginatedCards() {
    //   const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    //   const endIndex = startIndex + this.itemsPerPage;
    //   return this.cards.slice(startIndex, endIndex);
    // },
    paginatedCards() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;

      // Vérifier si la page actuelle est valide
      if (startIndex >= this.filteredRepa.length || endIndex <= 0) {
        return []; // Retourner un tableau vide si la page est invalide
      }

      return this.filteredRepa.slice(startIndex, endIndex);
    },
    // Vérifie si le bouton "Charger plus" doit être affiché
    showLoadMoreButton() {
      return this.currentPage * this.itemsPerPage < this.filteredRepa.length;
    },
    // pages() {

    //   return Array.from({ length: this.totalPages }, (_, index) => index + 1);
    // },
    pages() {
      const totalCards = this.filteredRepa.length;
      const totalPages = Math.ceil(totalCards / this.itemsPerPage);
      return Array.from({ length: totalPages }, (_, index) => index + 1);
    },
  },
  methods: {
    truncateDescription(description, maxLength) {
      if (!description) {
        return ""; // Si la description est nulle, retourner une chaîne vide
      }

      const words = description.split(" ");
      if (words.length <= maxLength) {
        return description; // Si le nombre de mots est inférieur ou égal à maxLength, retourner la description complète
      } else {
        const truncatedWords = words.slice(0, maxLength);
        return truncatedWords.join(" ") + "...";
      }
    },
    async restaurant() {
      try {
        const response = await axios.get("/api/restaurants");
        if (response.data) {
          this.restaurants = response.data.data;
          this.filteredRestaurants = this.restaurants.filter(
            (restaurant) => restaurant.user.id === this.user
          );
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    statusClass(status) {
      return {
        'bg-green-500': status === 'disponible',
        'bg-red-500': status === 'occuper'
      };
    },
  },
};
</script>

<style scoped>
.custom-background {
  /* Remplacez 'votre-image.jpg' par le nom de votre image */
  background-size: cover;
  /* Ajustez la taille de l'image */
  background-repeat: no-repeat;
  background-position: center center;
  /* Centrez l'image */
  /* Ajoutez d'autres styles Tailwind CSS ou CSS personnalisés au besoin */
}
.bg-green-500 {
  background-color: #38a169; /* Vert */
}

.bg-red-500 {
  background-color: #e53e3e; /* Rouge */
}
/* Ajoutez des styles personnalisés pour le slider ici */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
